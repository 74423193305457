
.admin-page-wrapper {
  padding: 50px 50px 90px;
  width: 100%;

  .admin-page-header {
    margin-left: 20px;
    margin-bottom: 20px;

    .admin-page-header-subtext {
      margin-bottom: 0;
      font-size: 16px;
    }
  }
}
