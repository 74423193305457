
.app-table-wrapper {
  margin-top: 20px;
  background-color: $white;
  font-size: 14px;
  table-layout: auto;

  .app-table-header {
    .app-table-row {

      .app-table-cell {
        margin-bottom: 0;
        padding: 6px 24px 6px 16px;
        font-family: "MulishBold", sans-serif;
        color: $navBarBlack;
        letter-spacing: 0.3px;

        &.set-width-column-350 {
          width: 40%;
        }
      }
    }
  }

  .app-table-body {

    .app-table-row {

      .app-table-cell {
        margin-bottom: 0;
        color: $navBarBlack;
      }

      &:last-child {

        .app-table-cell {
          border-bottom: none;
        }
      }

    }
  }

}

.sub-table {
  background-color: $backgroundGrey;
  border-radius: 5px;
}

.no-data-for-view {
  margin: 20px;
  font-family: 'SofiaPro-Bold', sans-serif;

  &.in-card {
    margin: 0;
  }
}

.table-row {

  .table-cell {
    margin-bottom: 0;
    padding-bottom: 10px;
  }

  &:last-child {
    .table-cell {
      border-bottom: none;
      margin-bottom: 0;
      padding-bottom: 10px;
    }
  }
}

.remove-row-border {

  &.MuiTableCell-root {
    border-bottom: none;
  }
}

.highlighted-row {
  background-color: $backgroundGrey;
}

.set-width {
  white-space: nowrap;
  width: 60px;

  &.wider {
    width: 220px;
  }

  &.thin {
    width: 25%;
  }
}

.set-thin-width {
  white-space: nowrap;
  width: 20%;
}

.form-error-message {
  margin-top: 8px;
  padding: 8px 16px;
  border-radius: 5px;
  background-color: $ordrslipRed;
  color: $white;
  font-family: 'SofiaPro-Bold', sans-serif;
  font-size: 14px;

  .error-icon {
    margin-right: 8px;
    height: 24px;
    vertical-align: middle;
  }
}

.fourth-table-width {
  width: 25%;
}

.half-table-width {
  width: 50%;
}

.MuiTablePagination-selectIcon {
  color: $ordrslipRed;
}

.MuiTablePagination-select {
  position: relative;
  top: 2px;
}

.MuiTablePagination-actions {
  .MuiIconButton-root {
    color: $ordrslipRed;

  }
  .Mui-disabled {
    color: $disabledGrey;
  }
}