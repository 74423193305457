.all-orders-accepted {
    min-height: 55px;
    border: 1px solid #A8A8A8;
    border-radius: 2px;
    opacity: 1;
    width: 100%;
}

.circle-padding {
    padding-right: 15px;
}

.alarm-padding {
    padding-right: 10px;
}

.MuiListItemIcon-root {
    display: inline;
    min-width: 20px;
}

.MuiList-padding, .MuiList-root {
    padding-top: 0;
    padding-bottom: 0;

    .MuiTypography-body1 {
        font-size: 12px;
    }
}

.MuiListItem-gutters {
    padding-top: 0;
    padding-bottom: 0;
}