.location-name-check {
  padding-left: 7px;

  &.no-check {
    padding-left: 19px;
  }
}

.menu-details {
  background-color: $backgroundGrey;
  opacity: 5;
  width: 100%;
  padding: 20px 15px 20px 20px;
  border-radius: 8px;
  margin-bottom: 20px;
}