.app-drawer {
    background-color: $backgroundGrey;
    width: 30vw;

    .app-card {
        margin-top: 0;
    }

    .drawer-info-container {
        background-color: $backgroundGrey;
        padding: 65px 20px 75px 20px;
        z-index: 5;
    }

    .drawer-footer {
        padding: 10px;
        margin: 20px 0 0 0;
        background-color: $white;
        box-shadow: 0px -2px 4px #00000039;
        position: fixed;
        width: 30vw;
        bottom: 0;
    }

    .drawer-header-title {
        vertical-align: middle;
        font-size: 16px;
        font-family: 'MulishExtraBold', sans-serif;
        background-color: $white;
        padding: 15px;
        text-align: center;
        box-shadow: 0px 2px 4px $disabledGrey;
        position: fixed;
        width: 30vw;
        top: 0;
        z-index: 10;

        p {
            vertical-align: middle;
            float: right;
            margin: 0;
            margin-top: 5px;
        }
    }

    .app-card-subheader {
        font-size: 14px;
        font-family: 'SofiaProBlack', sans-serif;
    }

    .user-info {
        text-align: left;
        margin-bottom: 1rem;

        p {
            margin: 0;
            font-size: 14px;
        }

    }

    .rewards-container {
        display: flex;
        flex-direction: row;
        padding-bottom: 10px;

        .loyalty-reward-text {
            margin: 0;
            font-size: 14px;
            font-family: 'SofiaProBlack', sans-serif;
        }

        .loyalty-reward-points {
            font-size: 10px;
            text-transform: uppercase;
        }
    }

}

.loyalty-popover {
    padding: 15px;
    font-size: 14px;
    background-color: $white;
    cursor: pointer;
}

.PrivateTabIndicator-colorSecondary-10 {
    background-color: $ordrslipRed;
}

.MuiTab-textColorSecondary.Mui-selected {
    color: $ordrslipRed;
    font-family: 'SofiaProBlack', sans-serif;
}

.cat-items {
    margin: 10px 0;
    padding: 5px 0;
    text-align: center;
}

.cat-items:hover, .category_active{
    font-size: large;
    font-weight: 700;
    background-color: rgba(107, 108, 108, 0.06);
    margin: 10px -24px;
    padding: 5px 24px;
}
