$backgroundGrey: #F0F0F0;
$brightGreen: #55DA38;
$disabledGrey: #AAAAAA;
$editHighlight: #6B6C6C;
$errorRed: #FF5252;
$navBarBlack: #17181A;
$noticeYellow: #FCDA00;
$ordrslipRed: #F93822;
$primaryBlack: #313131;
$secondaryBlue: #0077CC;
$secondaryGrey: #757575;
$tertiaryOrange: #ED9A28;
$white: #FFFFFF;
$darkGreen: #3D9928;