.table-wrapper {
  margin-top: 20px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 2px 2px 0px rgba(51, 46, 46, 0.4);
}

.inside-table-padding {
  padding: 20px;

  &.table-height {
    height: 750;
  }
}

.smaller-left-spacing {
    margin-left: 10px;
}

.top-margin-20 {
    margin-top: 20px;
}

.top-margin-10 {
    margin-top: 10px;
}

.top-margin-5 {
    margin-top: 5px;
}
