
.app-field-wrapper {
  margin-bottom: 16px;
}

.app-field-label {
  font-family: 'MulishBold', sans-serif;
  margin-bottom: 8px;
  display: block;
  color: $primaryBlack;
  font-size: 12px;
  opacity: 0.9;
  text-transform: uppercase;
}

.app-input-field {
  padding: 8px 16px;
  display: block;
  width: 100%;
  background-color: $white;
  border: 1px solid $disabledGrey;
  border-radius: 5px;
  font-size: 14px;

  &.indented {
    margin-left: 25px;
    width: 150px;
  }

  &:focus {
    outline: none;
  }

  ::placeholder {
    color: $disabledGrey;
  }

  &.no-padding {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 3px 16px;
    text-align: center;
  }
}

.error-message {
  margin-top: 8px;
  padding: 16px;
  border-radius: 5px;
  background-color: $noticeYellow;
  font-family: 'MulishBold', sans-serif;

  &.not-bold {
    font-family: 'Mulish', sans-serif;
  }

  span.bold {
    font-family: 'MulishExtraBold', sans-serif;
  }

  .error-icon {
    margin-right: 8px;
    height: 24px;
    vertical-align: middle;
  }
}

.app-button {
  padding: 5px 15px;
  border-radius: 100px;
  font-family: 'MulishBold', sans-serif;
  background-color: transparent;
  border: 2px solid $ordrslipRed;
  color: $ordrslipRed;

  &.primary {
    background-color: $ordrslipRed;
    color: $white;
  }

  &.margin-left {
    margin-left: 10px;
  }
}

.inputLabel {
  font-size: 12px;
}

.MuiRadio-root:hover {
  background-color: $white;
}

.MuiRadio-colorSecondary.Mui-checked:hover {
  background-color: $white;
}

.MuiInputBase-input {
  font-size: 14px;
  font-family: "Mulish", sans-serif;
}

input[type="time"]::-webkit-calendar-picker-indicator {
  background: none;
  display: none;
}
