@import "./styles/vars.scss";
@import "./styles/adminPage.scss";
@import "./styles/card.scss";
@import "./styles/drawer.scss";
@import "./styles/formElements.scss";
@import "./styles/integrations.scss";
@import "./styles/locations.scss";
@import "./styles/login.scss";
@import "./styles/menus.scss";
@import "./styles/navBar.scss";
@import "./styles/locations.scss";
@import "./styles/messages.scss";
@import "./styles/table.scss";

@font-face {
  font-family: 'Sofia';
  src: url('./assets/fonts/Sofia\ Pro/Mostardesign\ -\ SofiaProRegular.otf') format('opentype');
}

@font-face {
  font-family: 'SofiaPro-Bold';
  src: url('./assets/fonts/Sofia\ Pro/Mostardesign\ -\ SofiaPro-Bold.otf') format('opentype');
}

@font-face {
  font-family: 'SofiaProBlack';
  src: url('./assets/fonts/Sofia\ Pro/Mostardesign\ -\ SofiaProBlack.otf') format('opentype');
}

@font-face {
  font-family: 'Mulish';
  src: url('./assets/fonts/Mulish/Mulish\-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'MulishBold';
  src: url('./assets/fonts/Mulish/Mulish\-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'MulishExtraBold';
  src: url('./assets/fonts/Mulish/Mulish\-ExtraBold.ttf') format('truetype');
}

$family-sans-serif: 'Mulish', sans-serif;
$body-rendering: geometricPrecision;
@import "../node_modules/bulma/bulma.sass";

html {
  overflow: hidden !important;
}

body {
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-weight: normal;
}

.display-in-line {
  display: flex;
  flex-direction: row;

  &.align-items {
    justify-content: space-between;
  }

  &.center-items {
    align-items: center;
  }
}

.search-results {
  height: 100px;
  overflow: scroll;
  border: 1px solid $disabledGrey;

  .result {
    padding-left: 10px;
  }

  .highlighted-result {
    background-color: $disabledGrey;
    padding-left: 10px;
  }
}

.item-location-list {
  max-height: 300px;
  overflow: scroll;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 10px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: $primaryBlack;
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
  }
}

.cursor-pointer {
  cursor: pointer;
}

.overflow-text {
  overflow-wrap: break-word;
}

.divider {
  width: 100%;
  border-bottom: 1px solid $disabledGrey;
}

.profile-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 10;
}

.app-image-wrapper {
  position: relative;
  background-color: $white;
}

// Override Material UI Styles

.MuiPickersToolbar-toolbar,
.MuiPickersDay-daySelected,
.MuiTab-root {
  background-color: $primaryBlack;
}

.MuiButton-label {
  color: $primaryBlack;
}

.MuiPickersDay-daySelected:hover,
.MuiPickerDTTabs-tabs {
  background-color: $disabledGrey;
}

.MuiPickersDay-current {
  color: $ordrslipRed;
}

.MuiPickersClock-pin,
.MuiPickersClockPointer-pointer,
.MuiPickersClockPointer-noPoint {
  background-color: $ordrslipRed;
}

.MuiPickersClockPointer-thumb {
  border: 14px solid $ordrslipRed;
}

.MuiTypography-h4,
.MuiTypography-subtitle1,
.MuiTypography-body1,
.MuiTypography-body1 {
  font-size: 14px;
}

// Color Classes

.disabledGrey {
  color: $disabledGrey;
}

.backgroundGrey {
  color: $backgroundGrey;
}

.tertiaryOrange {
  color: $tertiaryOrange;
}

.primaryBlack {
  color: $primaryBlack;
}

.ordrslipRed {
  color: $ordrslipRed;
}

.secondaryBlue {
  color: $secondaryBlue;
}

.secondaryGrey {
  color: $secondaryGrey;
}

.brightGreen {
  color: $brightGreen;
}

.errorRed {
  color: $errorRed;
}

.red-border {
  border: 2px solid $ordrslipRed;
}

// Typography

.bold {
  font-family: 'MulishExtraBold', sans-serif;
}

.semi-bold {
  font-family: 'MulishBold', sans-serif;}

.large-text {
  font-size: 16px;
  font-family: 'SofiaPro-Bold', sans-serif;
}

.medium-text {
  font-size: 14px;
}

.small-text {
  font-size: 12px;
}

.red-error-text {
  color: $ordrslipRed;
  font-size: 12px;
}

.blueLinkText {
  color: $secondaryBlue;
  cursor: pointer;
  font-size: 16px;
  font-family: 'MulishBold', sans-serif;
}

.redLinkText {
  color: $ordrslipRed;
  cursor: pointer;
  font-size: 14px;
  font-family: 'MulishBold', sans-serif;
}

.input-label-subtext {
  opacity: 0.8;
  font-style: italic;
  font-size: 15px;
}

// Loading Spinner

.loading-box {
  padding: 20px;
  background-color: $white;

  .spinner-wrapper {
    display: flex;
    justify-content: center;
  }

  .text-wrapper {
    font-family: 'SofiaProBlack', sans-serif;
  }
}

// Popover
.popover-wrapper {
  padding: 25px;
}

.color-picker-button {
  height: 25px;
  width: 100px;
  border: 2px solid $disabledGrey;
  border-radius: 5px;
}