
.app-card {
  margin-top: 20px;
  background-color: $backgroundGrey;
  border-radius: 8px;

  .app-card-header {
    padding: 20px;
    display: flex;
    justify-content: space-between;
    background-color: $white;
    border-radius: 8px 8px 0 0;

    .app-card-header-text {

      .app-card-header-title {
        margin-bottom: 0;
        padding-bottom: 10px;
        color: $primaryBlack;
        font-size: 16px;
        font-family: 'MulishExtraBold', sans-serif;
      }

      .app-card-header-subtext {
        margin-bottom: 0;
        font-size: 16px;
      }
    }
  }

  .app-card-content-wrapper {
    padding: 20px;
    background-color: $white;

    .app-card-red-text {
        margin-bottom: 0;
        color: $ordrslipRed;
        font-size: 16px;
        opacity: 0.9;
    }

    .app-card-section-wrapper {
      margin-bottom: 20px;

      .app-card-section-title {
        margin-bottom: 0;
        color: $primaryBlack;
        background-color: $white;
        font-size: 14px;
        font-family: 'SofiaProBlack', sans-serif;
        opacity: 0.9;
        text-transform: uppercase;
      }
    }
  }

  .app-card-actions-wrapper {
    display: flex;
    justify-content: flex-end;
    padding: 20px;
    background-color: $white;

    &.center {
      justify-content: center;
    }
  }
}

.help-icon {
  color: $secondaryBlue;
  padding: 4px 8px 8px 8px;
}