.divider {
    padding-top: 10px;
    padding-bottom: 0;
    border-bottom: 1px solid $primaryBlack;
}

.messageBorder {
    border: $disabledGrey 1px solid;

    &.border-padding {
        padding: 20px;
    }
}

.notification-title {
    font-size: 18px;
    font-family: 'MulishBold', sans-serif;
}

.green-success-box {
    background-color: $brightGreen; 
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    padding: 10px;
    margin-top: 10px;
}

.white-success-text {
    font-size: 14px;
    color: $white;
    padding-left: 5px;
}
