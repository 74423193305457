
.login-page-wrapper {
  background-color: $backgroundGrey;

  .login-page-column {
    padding: 40px;
    width: 50vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .app-card {
      padding: 20px;
      background-color: $white;
      max-width: 550px;
    }

    .app-card-header {
      background-color: $white;
    }

    .app-card-content-wrapper {
      background-color: $white;
      padding-top: 0;
    }
  }
}

.password-check {
  font-size: 11px;
  padding-top: 10px;
}

.check-password-icon {
  color: $brightGreen;
  margin: 0 5px;
}
