.sync-text {
  color: #fff;
  font-size: 12px;
  text-align: center;
  margin-top: 10px;

  &.version-text {
    color: $backgroundGrey;

    &.bottom-spacing {
      padding-bottom: 15px;
    }
  }
}

.link-title {
  color: $white;
  font-size: 14px;
  font-family: 'SofiaProBlack', sans-serif;
  align-items: center;
}

.display-column {
  display: flex;
  flex-direction: column;
}

.svgIcon {
  height: 22px;
  width: 22px;
  color: $white;

  &.active {
    color: $ordrslipRed;
  }
}

.footer-links {
  text-decoration: underline;
  color: $white;
  padding-left: 5px;
  padding-right: 5px;
  cursor: pointer;

  :hover {
    text-decoration: none;
  }
}

.header-icon {
  height: 25px;
  width: auto;
}

.float-bottom {
  margin-top: auto;
}